import React, { Component } from 'react'
import warningIcon from './warning.svg'
import closeIcon from './close-icon.svg'
import './register-form.scss'
import '../../global/_global-styles.scss'

const ErrorMessage = () => {
  return (
    <div className="error-message">
      <img src={warningIcon} />
      <p className="error">Please insert valid input</p>
    </div>
  )
}
/**
 * Register form component
 */
class RegisterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      company: '',
      message: '',
      isSubmited: false,
      isSuccess: false,
      isEmailCorrect: false,
      terms: '',
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.validateEmail = this.validateEmail.bind(this)
  }

  validateEmail = email => {
    let emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    let emailResult = emailReg.test(email)
    emailResult
      ? this.setState({ isEmailCorrect: true })
      : this.setState({ isEmailCorrect: false })
  }

  onSubmit = e => {
    e.preventDefault()

    this.setState({ isSubmited: true })
    this.validateEmail(this.state.email)

    if (
      this.state.name !== '' &&
      this.state.isEmailCorrect &&
      this.state.phone !== ''
    ) {
      const url = `https:///wp.bvv.is/wp-json/bvv/v1/send-email?name=${this.state.name}&email=${this.state.email}&phone=${this.state.phone}&company=${this.state.company}&message=${this.state.message}&event=${this.props.eventTitle}`
      fetch(url)
        .then(response => {
          this.setState({ isSuccess: true })
        })
        .catch(error => console.error('Error:', error))
    }
  }

  render() {
    return (
      <div className="register-form">
        <div className="register-form__wrapper">
          <h1>SKRÁNING </h1>
          <form encType="application/x-www-form-urlencoded">
            <div className="register-form__field-wrap">
              <span> Nafn</span>
              <input
                type="text"
                className="register-input-field"
                onChange={e => this.setState({ name: e.target.value })}
              />
              {this.state.isSubmited && this.state.name === '' ? (
                <ErrorMessage />
              ) : null}
            </div>
            <div className="register-form__field-wrap">
              <span> Netfang </span>
              <input
                type="email"
                className="register-input-field"
                onChange={e => this.setState({ email: e.target.value })}
              />
              {this.state.isSubmited && !this.state.isEmailCorrect ? (
                <ErrorMessage />
              ) : null}
            </div>
            <div className="register-form__field-wrap">
              <span> Símanúmer </span>
              <input
                type="number"
                className="register-input-field"
                onChange={e => this.setState({ phone: e.target.value })}
              />
              {this.state.isSubmited && this.state.phone === '' ? (
                <ErrorMessage />
              ) : null}
            </div>
            <div className="register-form__field-wrap">
              <span> Fyrirtæki / Stofnun </span>
              <input
                type="text"
                className="register-input-field"
                onChange={e => this.setState({ company: e.target.value })}
              />
            </div>
            <div className="register-form__field-wrap">
              <span> Skilaboð </span>
              <textarea
                className="register-textarea class:register-input-field"
                onChange={e => this.setState({ message: e.target.value })}
              />
            </div>
            <div className="register-form__checkbox-wrap">
              <div
                className={
                  this.state.terms
                    ? 'register-form__ckeckbox-mask register-form__ckeckbox-mask--checked'
                    : 'register-form__ckeckbox-mask'
                }
                onClick={() =>
                  this.setState({ terms: this.state.terms ? false : true })
                }></div>
              <input type="checkbox" id="terms-checkbox" name="terms" />
              <span>
                MEÐ ÞVÍ AÐ SMELLA Á SKRÁNINGU SAMÞYKKIR ÞÚ NOTENDASKILMÁLA
                OKKAR, Þ.Á.M. PERSÓNUVERNDARSTEFNU.
              </span>
              {this.state.isSubmited && !this.state.terms ? (
                <ErrorMessage />
              ) : null}
            </div>
            <div
              className="button button--primary submit-button"
              onClick={this.onSubmit}>
              Skráning á viðburð
            </div>
          </form>
        </div>
        {this.state.isSuccess ? (
          <div className="register-form__succes-dialog-wrapp">
            <div className="register-form__succes-dialog">
              <div
                className="close-form"
                onClick={() => this.setState({ isSuccess: false })}>
                <img src={closeIcon} />
              </div>
              <h1>Skráning send</h1>
              <p>
                Þú hefur sent skráningu á viðburðinn. Vinsamlegast athugið
                tölvupóst þinn með staðfestingu.
              </p>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default RegisterForm
