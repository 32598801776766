/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from './Header'
import HeaderImage from '../bvv-styleguide/components/header-image/HeaderImage'
import RegisterForm from '../bvv-styleguide/components/register-form/RegisterForm'
import SocialsShareTitle from '../bvv-styleguide/components/socials-share-title/SocialsShareTitle'
import Footer from './Footer'

import './global.scss'

const SingleArticleLayout = ({
  headerImage,
  title,
  date,
  children,
  articleUrl,
  slug,
}) => {
  //Form state
  return (
    <>
      <Helmet title={`${title}| BVV`}>
        <script
          src="https://www.google.com/recaptcha/api.js?render=6Lef7t8UAAAAAMZRviDmSMJMxRR_xLAjK0JQmKz7"
          async
          defer
        />
      </Helmet>
      <Header />
      <HeaderImage bgImageUrl={headerImage} />
      <div className="post-content">
        <SocialsShareTitle
          title={title}
          date={date}
          articleUrl={articleUrl}
          slug={slug}
          cptName="vidburdir"
        />
        <main>{children}</main>
        <RegisterForm eventTitle={title} />
      </div>
      <Footer />
    </>
  )
}

SingleArticleLayout.propTypes = {
  //Featured image
  headerImage: PropTypes.string,
  //Post title
  title: PropTypes.string.isRequired,
  //Publish date
  date: PropTypes.string,
  //Components from admin
  children: PropTypes.node.isRequired,
  // Post Url
  articleUrl: PropTypes.string,
  //Slug
  slug: PropTypes.string,
}

export default SingleArticleLayout
