import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SingleArticleLayout from '../components/singleArticleLayout'
import Seo from '../components/Seo'
//IF user don't provide featured image
import defaultImage from '../images/single-cpt-placeholder.png'

export const SingleEventTemplate = ({ content }) => {
  return (
    <div
      className="cpt-content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

SingleEventTemplate.propTypes = {
  content: PropTypes.string,
}

const SingleEvent = ({ data }) => {
  const {
    wordpressWpEvent: { title, content, date, featured_media, link, slug },
  } = data

  const shareContent = content.slice(4, 60)
  return (
    <SingleArticleLayout
      title={title}
      date={date}
      headerImage={!featured_media ? defaultImage : featured_media.source_url}
      articleUrl={link}
      slug={slug}>
      <Seo
        title={title}
        description={shareContent}
        imageSrc={featured_media ? featured_media.source_url : null}
      />
      <SingleEventTemplate content={content} />
    </SingleArticleLayout>
  )
}

SingleEvent.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SingleEvent

export const singleEventQuery = graphql`
  query postById($id: String!) {
    wordpressWpEvent(id: { eq: $id }) {
      title
      content
      slug
      date
      link
      featured_media {
        source_url
      }
    }
  }
`
